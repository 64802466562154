import { useAppSelector } from "@app/hooks";
import { STATUS } from "@features/design/components/ExpandBlockHandler";
import { DotLoading, InfiniteScroll } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  limit: number;
  affiliateProductGroupData: any;
  onUpdateGroupLimitation: (val) => void;
  productGroupId: string;
  expandOrCollapseStatus: string;
};

const ADDITION_COUNT = 3;

const ProductInfiniteScroll = (props: Props) => {
  const {
    limit,
    affiliateProductGroupData,
    onUpdateGroupLimitation,
    productGroupId,
    expandOrCollapseStatus,
  } = props;
  const { t } = useTranslation();

  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const isOpenProductTourDynamic = dynamicConfig?.isOpenProductTourDynamic;

  useEffect(() => {
    if (limit > ADDITION_COUNT) {
      const element = document.getElementById(`product-group-${limit - 2}`);
      if (element) {
        element.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }
  }, [limit]);

  async function loadMoreIcon() {
    await sleep(500);
    onUpdateGroupLimitation(ADDITION_COUNT);
  }

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span
              style={{
                color: "#999999",
              }}
            >
              {t("ss_builder_loading_label")}
            </span>
            <DotLoading
              style={{
                color: "#999999",
              }}
            />
          </>
        ) : null}
      </>
    );
  };

  const hasMoreGroup = useMemo(() => {
    return limit < affiliateProductGroupData?.length;
  }, [limit, JSON.stringify(affiliateProductGroupData)]);

  const isShowInfiniteScroll = useMemo(
    () =>
      !!(
        hasMoreGroup &&
        !isOpenProductTourDynamic &&
        !productGroupId &&
        expandOrCollapseStatus !== STATUS.COLLAPSE
      ),
    [
      hasMoreGroup,
      isOpenProductTourDynamic,
      productGroupId,
      expandOrCollapseStatus,
    ]
  );

  return (
    <>
      {isShowInfiniteScroll && (
        <div id="product-group-infinite-scroll">
          <InfiniteScroll
            loadMore={loadMoreIcon}
            hasMore={hasMoreGroup}
          >
            <InfiniteScrollContent hasMore={hasMoreGroup} />
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

export default ProductInfiniteScroll;
