import { Button, Popover } from "antd-mobile";
import React, { useMemo, useRef, useState } from "react";
import { Action } from "antd-mobile/es/components/popover";
import {
  ArrowDown,
  ArrowUp,
  DeleteIcon,
  EditTabIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  LineRename,
  MoreIcon,
} from "@share/icons";
import { useTranslation } from "react-i18next";
import FullScreenPopup from "@share/components/full-screen-popup";
import ProductGroupQuickEdit from "../ProductGroupQuickEdit";
import { useAppDispatch } from "@app/hooks";
import { updateBlock } from "@features/main/store/positionsSlice";
import PopupComponent from "@share/components/PopupComponent";
import AffiliateProductGroupAddNew from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductGroupManagement/AffiliateProductGroupAddNew";
import { useModal } from "@share/hooks/modal";
import { useToast } from "@share/hooks/toast";

export enum GroupActions {
  VISIBLE_HIDE_GROUP = "visible-hide-group",
  MOVE_UP_GROUP = "move-up-group",
  MOVE_DOWN_GROUP = "move-down-group",
  EDIT_GROUP = "edit-group",
  EDIT_GROUP_NAME = "edit-group-name",
  DELETE_GROUP = "delete-group",
}

enum GroupVisibleHidden {
  VISIBLE = 1,
  HIDDEN = 2,
}

const QuickActionsComponent = ({
  groupItem,
  productGroupId,
  affiliateProductGroupData,
  productGroupMaximum,
  blockData,
  positionSlug,
  tabUid,
  isCreatedNew,
  onGetLatestBlockData = (data) => {},
}) => {
  const popoverContainerRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { ErrorModal, ConfirmModal } = useModal();
  const { SuccessToast } = useToast();

  const [isOpenGroupEdit, setIsOpenGroupEdit] = useState(false);
  const [isOpenAddNewProductGroup, setIsOpenAddNewProductGroup] =
    useState(false);

  const isEnable = groupItem?.enable;
  const groupIndex = affiliateProductGroupData?.findIndex(
    (x) => x?.uid === groupItem?.uid
  );

  const affiliateProductGroupListEnable = useMemo(
    () => affiliateProductGroupData?.filter((x) => x?.enable === 1),
    [JSON.stringify(affiliateProductGroupData)]
  );

  const actions: Action[] = [
    {
      key: GroupActions.EDIT_GROUP,
      icon: (
        <EditTabIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_edit_block_label"),
    },
    {
      key: GroupActions.MOVE_UP_GROUP,
      icon: (
        <ArrowUp
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_move_up_block_label"),
    },
    {
      key: GroupActions.MOVE_DOWN_GROUP,
      icon: (
        <ArrowDown
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_move_down_block_label"),
    },
    {
      key: GroupActions.VISIBLE_HIDE_GROUP,
      icon:
        isEnable === GroupVisibleHidden.VISIBLE ? (
          <EyeVisibleIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ) : (
          <EyeHiddenIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ),
      text:
        isEnable === GroupVisibleHidden.VISIBLE
          ? t("ss_builder_hide_product_group_label")
          : t("ss_builder_show_product_group_label"),
    },
    {
      key: GroupActions.EDIT_GROUP_NAME,
      icon: (
        <LineRename
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_edit_product_group_label"),
    },
    {
      key: GroupActions.DELETE_GROUP,
      icon: (
        <DeleteIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_delete_product_group_label"),
    },
  ];

  const handleClickPopoverItem = (item: Action) => {
    const key = item?.key;
    switch (key) {
      case GroupActions.EDIT_GROUP:
        onEditGroup();
        break;
      case GroupActions.MOVE_UP_GROUP:
        onMoveGroup("up");
        break;
      case GroupActions.MOVE_DOWN_GROUP:
        onMoveGroup("down");
        break;
      case GroupActions.VISIBLE_HIDE_GROUP:
        const newStatus = isEnable === 1 ? 2 : 1;
        onVisibleHideGroup(newStatus);
        break;
      case GroupActions.EDIT_GROUP_NAME:
        onEditGroupName();
        break;
      case GroupActions.DELETE_GROUP:
        onRemoveGroup();
        break;
    }
  };

  const onEditGroup = () => {
    setIsOpenGroupEdit(true);
  };

  const actionsFilter = useMemo(() => {
    let actionsClone = actions;

    if (groupIndex === 0) {
      actionsClone = actionsClone?.filter(
        (x) => x?.key !== GroupActions.MOVE_UP_GROUP
      );
    }
    if (groupIndex === affiliateProductGroupData?.length - 1) {
      actionsClone = actionsClone?.filter(
        (x) => x?.key !== GroupActions.MOVE_DOWN_GROUP
      );
    }
    if (affiliateProductGroupData?.length <= 1) {
      actionsClone = actionsClone?.filter(
        (x) =>
          x?.key === GroupActions.EDIT_GROUP ||
          x?.key === GroupActions.EDIT_GROUP_NAME ||
          x?.key === GroupActions.VISIBLE_HIDE_GROUP
      );
    }
    return actionsClone;
  }, [groupIndex, actions]);

  const handleUpdateBlock = (data) => {
    const newDataAffiliateProduct = {
      ...blockData,
      content_attributes: {
        ...blockData.content_attributes,
        items: data,
        is_created_new: isCreatedNew,
      },
    };
    if (positionSlug) {
      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: newDataAffiliateProduct,
        })
      );
    }
    onGetLatestBlockData(newDataAffiliateProduct);
  };

  const onMoveGroup = (action) => {
    const startIndex = groupIndex;
    const endIndex = action === "down" ? startIndex + 1 : startIndex - 1;
    if (endIndex < 0 || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    handleUpdateBlock(itemsOrder);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(affiliateProductGroupData);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedBlockList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedBlockList;
  };

  const onVisibleHideGroup = (status) => {
    const newGroupData = affiliateProductGroupData?.map((ele) => {
      return ele?.uid === groupItem?.uid
        ? {
            ...ele,
            enable: status,
          }
        : ele;
    });
    if (isEnable !== 1) {
      handleUpdateBlock(newGroupData);
      SuccessToast(t("ss_builder_enable_product_group_successfully_label"));
    } else {
      if (affiliateProductGroupListEnable.length >= 2) {
        const onConfirm = () => {
          handleUpdateBlock(newGroupData);
          SuccessToast(
            t("ss_builder_disable_product_group_successfully_label")
          );
        };
        ConfirmModal({
          title: t("ss_builder_confirm_hide_product_group_modal_title"),
          description: t(
            "ss_builder_confirm_hide_product_group_modal_description",
            {
              group_name: groupItem?.content_attributes?.title_product_group,
            }
          ),
          confirmText: t("ss_builder_confirm_hide_product_label"),
          cancelText: t("ss_builder_cancel_hide_product_label"),
          onConfirm,
        });
      } else {
        ErrorModal({
          title: t("ss_builder_hide_product_group_error_title"),
          description: t("ss_builder_hide_product_group_error_content", {
            group_name: groupItem?.content_attributes?.title_product_group,
          }),
          confirmText: t(
            "ss_builder_hide_product_group_error_btn_confirm_label"
          ),
        });
      }
    }
  };

  const onRemoveGroup = () => {
    const newGroupData = affiliateProductGroupData?.filter(
      (x) => x?.uid !== groupItem.uid
    );
    if (affiliateProductGroupListEnable.length <= 1 && isEnable === 1) {
      ErrorModal({
        title: t("ss_builder_delete_product_group_error_title"),
        description: t("ss_builder_delete_product_group_error_content", {
          group_name: groupItem?.content_attributes?.title_product_group,
        }),
        confirmText: t(
          "ss_builder_delete_product_group_error_btn_confirm_label"
        ),
      });
    } else {
      const onConfirm = () => {
        handleUpdateBlock(newGroupData);
        SuccessToast(t("ss_builder_delete_product_group_successfully_label"));
      };
      ConfirmModal({
        title: t("ss_builder_confirm_delete_product_group_modal_title"),
        description: t(
          "ss_builder_confirm_delete_product_group_modal_description",
          {
            group_name: groupItem?.content_attributes?.title_product_group,
          }
        ),
        confirmText: t("ss_builder_confirm_delete_product_label"),
        cancelText: t("ss_builder_cancel_delete_product_label"),
        onConfirm,
      });
    }
  };

  const onEditGroupName = () => {
    setIsOpenAddNewProductGroup(true);
  };

  const handleEditGroup = (data) => {
    const newGroupData = affiliateProductGroupData?.map((ele) => {
      return ele?.uid === data?.uid ? data : ele;
    });
    handleUpdateBlock(newGroupData);
  };

  return (
    <div
      ref={popoverContainerRef}
      onClick={(e) => e.stopPropagation()}
    >
      <Popover.Menu
        mode="dark"
        actions={actionsFilter}
        placement="bottom-end"
        trigger="click"
        stopPropagation={["click"]}
        getContainer={() => document.querySelector(".design-content-class")}
        onAction={(item: Action) => handleClickPopoverItem(item)}
      >
        <Button
          size="mini"
          style={{
            border: "1px solid #EEEEEE",
            borderRadius: "4px",
            width: "26px",
            height: "26px",
            padding: "0px",
          }}
        >
          <MoreIcon
            className="fill-black m-auto"
            width={13}
            height={13}
          />
        </Button>
      </Popover.Menu>
      {isOpenGroupEdit && (
        <FullScreenPopup
          visible={isOpenGroupEdit}
          onMaskClick={() => setIsOpenGroupEdit(false)}
        >
          <ProductGroupQuickEdit
            onCancelProductGroupQuickEdit={() => setIsOpenGroupEdit(false)}
            affiliateProductGroupData={affiliateProductGroupData}
            activeProductGroup={productGroupId || groupItem.uid}
            blockData={blockData}
            productGroupMaximum={productGroupMaximum}
            positionSlug={positionSlug}
            tabUid={tabUid}
            isCreatedNew={isCreatedNew}
            onGetLatestBlockData={onGetLatestBlockData}
          />
        </FullScreenPopup>
      )}
      <PopupComponent
        visible={isOpenAddNewProductGroup}
        // onMaskClick={() => setIsOpenAddNewProductGroup(false)}
        title={t("ss_builder_edit_name_affiliate_product_group_title")}
        onClose={() => setIsOpenAddNewProductGroup(false)}
      >
        <AffiliateProductGroupAddNew
          onCancelAffiliateProductGroupAddNew={() =>
            setIsOpenAddNewProductGroup(false)
          }
          affiliateProductGroupDefault={groupItem}
          onSaveAffiliateProductGroupAddNew={handleEditGroup}
          affiliateProductGroupList={affiliateProductGroupData}
        />
      </PopupComponent>
    </div>
  );
};

export default QuickActionsComponent;
